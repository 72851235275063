import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rem, variables } from '@/assets/styled';

export const StyledLogoSpan = styled.span`
    width: 225px;
    height: 64px;

    @media only screen and (max-width: ${variables.screens.sm}) {
        width: 150px;
    }
`;

export const StyledCallUs = styled.div`
    .app-underline {
        height: 0.125rem;
        transition: transform 200ms ease;
    }

    &:hover {
        .app-underline {
            transform: translateY(${rem(2)});
        }
    }
`;

export const StyledHeader = styled.div`
    transition: top 0.5s;
`;

export const StyledHeaderBg = styled.div`
    z-index: -1;
    height: 100%;
    max-height: ${(props) => (props.hasScrolled ? rem(140) : 0)};
    transition: max-height 500ms ease;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background: ${variables.colors.grey.white};
    box-shadow: 0 0px 10px 2px rgba(0, 0, 0, 0.1);
`;

export const StyledNavIcon = styled.div`
    z-index: 100;
    width: ${rem(24)};

    &:after,
    &:before,
    & div {
        z-index: -1;
        background-color: ${variables.colors.text.default};
        border-radius: ${rem(8)};
        content: '';
        display: block;
        height: ${rem(3)};
        margin: ${rem(3)} 0;
        transition: transform 0.2s ease-in-out;
        will-change: transform;
    }

    &.active:before {
        transform: translateY(${rem(6)}) rotate(135deg);
        background-color: #fff;
    }

    &.active:after {
        transform: translateY(${rem(-6)}) rotate(-135deg);
        background-color: #fff;
    }

    &.active div {
        transform: scale(0);
        background-color: #fff;
    }
`;

export const StyledPopoverWrapper = styled.div`
    z-index: 20;
    background-color: transparent;
    pointer-events: none;

    &.menu-open {
        background-color: rgba(45, 53, 60, 0.2);
        pointer-events: all;
    }
`;

export const StyledMenuContactImageWrapper = styled.div`
    z-index: 2;
`;

export const StyledPopover = styled(motion.div)`
    background-image: url('/shapes/ripple-faded-1x.webp');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top right 100%;
    right: 0;
    margin-top: -100%;
    opacity: 0;
    height: calc(100vh - 64px);

    &::after {
        content: '';
        height: 32px;
        position: absolute;
        width: 100%;
        bottom: -16px;
        overflow: hidden;
        background-color: ${variables.colors.text.default};
        border-radius: 0;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.3s;

        @media only screen and (min-width: ${variables.screens.sm}) {
            width: 0px;
            top: -50%;
            width: 330px;
            height: 0;
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
            transition-delay: 0s;
        }

        @media only screen and (min-width: ${variables.screens.md}) {
            left: -5%;
        }
    }

    &.menu-open {
        margin-top: 0;
        opacity: 1;

        &::after {
            border-bottom-left-radius: 100%;
            border-bottom-right-radius: 100%;
        }
    }

    @media only screen and (min-width: ${variables.screens.sm}) {
        margin-top: 0;
        transform: translateX(330px);
        border-bottom-left-radius: 100%;
        height: 100vh;

        &.menu-open {
            transform: translateX(0);
            height: 100vh;
            min-width: 600px;
            max-width: 100%;
            border-bottom-left-radius: 0;

            &::after {
                margin-left: -10%;
                height: 200vh;
            }
        }
    }

    a {
        position: relative;
        z-index: 2;
    }

    a:not(.link-active):hover {
        color: ${variables.colors.blue.default};
    }

    a::after {
        visibility: hidden;
        content: '';
        height: 1rem;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        background: linear-gradient(-90deg, rgb(255, 90, 185) 0%, rgb(255, 120, 90) 100%);
        z-index: -1;
        border-radius: 1px;

        @media only screen and (min-width: ${variables.screens.sm}) {
            height: 1.5rem;
        }
    }

    a.link-active::after {
        visibility: visible;
    }
`;

export const StyledPopOverContent = styled.div``;
