import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@/components/Icon';
import { formattedDutchNumber } from '@/helpers/format';

const ContactInfo = ({ contact }) => (
    <div className="pt-1 sm:pt-0 pb-1 w-full">
        <h2 className="text-xl text-text font-black font-display">Contact&shy;gegevens</h2>
        <div className="mt-2 flex flex-col">
            <p>{contact?.address_details?.street}</p>
            <p>
                {contact?.address_details?.postal_code} {contact?.address_details?.city}
            </p>
            <p>{contact?.address_details?.country}</p>
            {contact?.contact_details?.email && (
                <a
                    className="mt-4 flex items-center"
                    href={`mailto:${contact?.contact_details?.email}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Icon name="mail" fill="text" size={20} />{' '}
                    <p style={{ wordBreak: 'break-word !important' }} className="ml-2 hover:text-pink-soft">
                        {contact?.contact_details?.email}
                    </p>
                </a>
            )}
            {contact?.contact_details?.phone && (
                <a
                    className="mt-2 flex items-center"
                    href={`tel:${formattedDutchNumber(contact?.contact_details?.phone)}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Icon name="phone" fill="text" size={20} />{' '}
                    <p className="ml-2 hover:text-pink-soft">{formattedDutchNumber(contact?.contact_details?.phone)}</p>
                </a>
            )}
            <div className="flex justify-start flex-grow mt-4">
                {contact?.socials?.linkedin && (
                    <a href={contact?.socials?.linkedin} target="_blank" rel="noreferrer">
                        <Icon className="mx-1" name="social-linkedin" size={32} />{' '}
                    </a>
                )}
                {contact?.socials?.whatsapp && (
                    <a href={contact?.socials?.whatsapp} target="_blank" rel="noreferrer">
                        <Icon className="mx-1" name="social-whatsapp" size={32} />{' '}
                    </a>
                )}
                {contact?.socials?.instagram && (
                    <a href={contact?.socials?.instagram} target="_blank" rel="noreferrer">
                        <Icon className="mx-1" name="social-instagram" size={32} />{' '}
                    </a>
                )}
            </div>
        </div>
    </div>
);

ContactInfo.propTypes = {
    contact: PropTypes.shape({
        address_details: PropTypes.shape({
            city: PropTypes.string,
            country: PropTypes.string,
            image: PropTypes.any,
            postal_code: PropTypes.string,
            street: PropTypes.string,
        }),
        contact_details: PropTypes.shape({
            email: PropTypes.string,
            phone: PropTypes.string,
        }),
        socials: PropTypes.shape({
            linkedin: PropTypes.string,
            whatsapp: PropTypes.string,
            instagram: PropTypes.string,
        }),
    }),
};

export default ContactInfo;
