import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useMagnetBlockShown from '@/hooks/useMagnetBlockShown';

const MagnetBlockContext = createContext(null);

export function MagnetBlockProvider({ children, initialValue = false }) {
    const state = useMagnetBlockShown(initialValue);
    return <MagnetBlockContext.Provider value={state}>{children}</MagnetBlockContext.Provider>;
}

export function useGlobalMagnetBlock() {
    const context = useContext(MagnetBlockContext);
    if (context === null) {
        throw new Error('useGlobalMagnetBlock must be used within a MagnetBlockProvider');
    }
    return context;
}

MagnetBlockProvider.propTypes = {
    children: PropTypes.node.isRequired,
    initialValue: PropTypes.bool,
};
