import React from 'react';
import PropTypes from 'prop-types';
import { StyledFooterLine } from './styles';

import ContactInfo from './FooterComponents/ContactInfo';
import ListRenderer from './FooterComponents/ListRenderer';
import renderFooterComponents from './FooterComponents/ComponentRenderer';
import CopyrightAndSocial from './FooterComponents/CopyrightAndSocial';

const Footer = ({ footerMenu, contact }) => (
    <div className="bg-grey-ghostWhite flex flex-col items-center pt-8">
        {/* Footer Header */}
        <div className="app-m-w md:px-8 px-6 flex py-6 w-full flex-col sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
            {footerMenu?.menu_sections.map((section, index) => (
                <ListRenderer key={index} section={section} />
            ))}
            {contact && <ContactInfo contact={contact} />}
        </div>

        <StyledFooterLine className="w-full flex border-grey-light" />

        {/* Footer Components */}
        <div className="app-m-w px-6 md:px-8 flex flex-wrap justify-between items-center">
            {renderFooterComponents(footerMenu ? footerMenu.components : [])}
        </div>

        <StyledFooterLine className="w-full flex border-grey-light" />

        {/* Footer's Footer */}
        <CopyrightAndSocial />
    </div>
);

Footer.propTypes = {
    footerMenu: PropTypes.shape({
        components: PropTypes.arrayOf(PropTypes.object),
        menu_sections: PropTypes.arrayOf(PropTypes.object),
    }),
    contact: PropTypes.shape({
        address_details: PropTypes.shape({
            city: PropTypes.string,
            country: PropTypes.string,
            image: PropTypes.any,
            postal_code: PropTypes.string,
            street: PropTypes.string,
        }),
        contact_details: PropTypes.shape({
            email: PropTypes.string,
            phone: PropTypes.string,
        }),
        socials: PropTypes.shape({
            linkedin: PropTypes.string,
            whatsapp: PropTypes.string,
            instagram: PropTypes.string,
        }),
    }),
};

export default Footer;
