import qs from 'qs';
import fetchAPI from './fetchAPI';

export async function getHomePage() {
    const data = await fetchAPI('home?populate=deep,5');
    return data?.data?.attributes;
}

export async function getOnsTeamPage() {
    const data = await fetchAPI('ons-team?populate=deep,5');
    return data?.data?.attributes;
}

export async function getOnsWerkPage() {
    const data = await fetchAPI('ons-werk?populate=deep,5');
    return data?.data?.attributes;
}

export async function getUsers(filters) {
    const data = await fetchAPI(`users?${filters}`);
    return data;
}

export async function getDienstenPage() {
    const data = await fetchAPI('diensten?populate=deep,5');
    return data?.data?.attributes;
}

export async function getHeaderMenu() {
    const data = await fetchAPI('main-menu?populate=deep,2');
    return data?.data?.attributes?.menu_items;
}

export async function getFootermenu() {
    const data = await fetchAPI('footer-menu?populate=deep,5');
    return data?.data?.attributes;
}

export async function getContactDetails() {
    const data = await fetchAPI('contact?populate=deep,5');
    return data?.data?.attributes;
}

export async function getPage(slug, prefix) {
    const filter = prefix
        ? {
              $and: [
                  {
                      slug: {
                          $eq: `${slug}`,
                      },
                  },
                  {
                      slug_prefix: {
                          $eq: `${prefix}`,
                      },
                  },
              ],
          }
        : {
              $and: [
                  {
                      slug: {
                          $eq: `${slug}`,
                      },
                  },
                  {
                      slug_prefix: {
                          $null: true,
                      },
                  },
              ],
          };

    const filters = qs.stringify(
        {
            filters: filter,
            populate: 'deep,5',
        },
        {
            encodeValuesOnly: true,
        }
    );

    const data = await fetchAPI(`pages?${filters}`);
    return data?.data[0]?.attributes;
}

export async function getAllPagesWithSlug() {
    const filter = {
        slug_prefix: {
            $null: true,
        },
    };

    const filters = qs.stringify(
        {
            filters: filter,
            pagination: {
                pageSize: 100,
            },
        },
        {
            encodeValuesOnly: true,
        }
    );

    const data = await fetchAPI(`pages?${filters}`);
    return data?.data;
}
export async function getAllPagesWithSlugPrefix() {
    const filter = {
        slug_prefix: {
            $null: false,
            $notIn: ['diensten', 'projecten', 'blog', 'vacatures'],
        },
    };

    const filters = qs.stringify(
        {
            filters: filter,
            pagination: {
                pageSize: 100,
            },
        },
        {
            encodeValuesOnly: true,
        }
    );

    const data = await fetchAPI(`pages?${filters}`);
    return data?.data;
}

export async function getBlogPageFull() {
    const data = await fetchAPI('blog-overview-page?populate=deep,5');
    return data?.data?.attributes;
}

export async function getAllBlogPages() {
    const filters = qs.stringify(
        {
            sort: ['publishedAt:desc'],
            populate: 'deep,2',
        },
        {
            encodeValuesOnly: true,
        }
    );
    const data = await fetchAPI(`blogs?${filters}`);
    return data?.data;
}

export async function getAllBlogPagesByTag(tag) {
    const filters = qs.stringify(
        {
            filters: {
                tags: {
                    name: {
                        $eq: `${tag}`,
                    },
                },
            },
            populate: 'deep,2',
        },
        {
            encodeValuesOnly: true,
        }
    );
    const data = await fetchAPI(`blogs?${filters}`);

    return data.data;
}

export async function getAllBlogTags() {
    // Filter only tags with blogs
    const filters = qs.stringify(
        {
            filters: {
                blogs: {
                    id: {
                        $gte: 1,
                    },
                },
            },
            populate: 'deep,2',
        },
        {
            encodeValuesOnly: true,
        }
    );
    const data = await fetchAPI(`tags?${filters}`);
    return data?.data;
}

export async function getBlogPage(slug) {
    const filters = qs.stringify(
        {
            filters: {
                slug: {
                    $eq: `${slug}`,
                },
            },
            populate: 'deep',
        },
        {
            encodeValuesOnly: true,
        }
    );
    const data = await fetchAPI(`blogs?${filters}`);

    return data?.data[0]?.attributes;
}

export async function getProjectPage(slug) {
    const filters = qs.stringify(
        {
            filters: {
                slug: {
                    $eq: `${slug}`,
                },
            },
            populate: 'deep',
        },
        {
            encodeValuesOnly: true,
        }
    );
    const data = await fetchAPI(`projects?${filters}`);

    return data?.data[0]?.attributes;
}

export async function getAllProjectPages() {
    const data = await fetchAPI('projects?populate=deep,2');
    return data?.data;
}

export async function getAllProjectsByTag(tag) {
    const filters = qs.stringify(
        {
            filters: {
                tags: {
                    name: {
                        $eq: `${tag}`,
                    },
                },
            },
            populate: 'deep,2',
        },
        {
            encodeValuesOnly: true,
        }
    );

    const data = await fetchAPI(`projects?${filters}`);
    return data?.data;
}

export async function getAllProjectTags() {
    // Filter only tags with projects
    const filters = qs.stringify(
        {
            filters: {
                projects: {
                    id: {
                        $gte: 1,
                    },
                },
            },
            populate: 'deep,2',
        },
        {
            encodeValuesOnly: true,
        }
    );

    const data = await fetchAPI(`tags?${filters}`);
    return data?.data;
}

export async function getVacaturePage() {
    const data = await fetchAPI(`vacature-pagina?populate=deep,5`);
    return data?.data?.attributes;
}

export async function getRedirects() {
    const data = await fetchAPI('redirects');
    return data?.data;
}

export async function sendMail(data, cta = false) {
    let url = 'send-email';

    if (cta) {
        url = 'send-cta-email';
    }

    const res = await fetch(`${process.env.NEXT_PUBLIC_STRAPI_API_URL}/${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const json = await res.json();

    if (json.errors) {
        console.error(json.errors);
        throw new Error('Failed to fetch API');
    }

    return {
        code: res.status,
        ...json,
    };
}
