export const variables = {
    colors: {
        pink: {
            default: '#FF5AB9', // Hot Pink
            soft: '#FF6F8C',
            // Pink gradient: Outrageous Orange -> Hot Pink
        },
        orange: {
            default: '#FF785A', // Outrageous Orange
        },
        blue: {
            default: '#0093FF', // Dodger Blue
            dark: '#0000B9', // Trypan Blue
            light: '#00C5FF', // Capri
            // Gradient Light: Light -> Primary
            // Gradient Dark: Primary -> Dark
        },
        green: {
            light: '#78EE8D',
            dark: '#43D854',
            // Gradient 'Whatsapp': Light -> Dark
        },
        text: {
            default: '#002643',
            variant: '#0C1D1D',
        },
        grey: {
            white: '#FFFFFF',
            ghostWhite: '#F4F4F9',
            blueLight: '#738898',
            blueDark: '#4E606E',
        },
    },
    screens: {
        xs: '450px',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
    },
    screenWidths: {
        xs: 450,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
    },
};

/**
 * Convert px to rem.
 * @param {number} px - Pixels to convert to rem
 */
export const rem = (px = 16) => `${px / 16}rem`;
