import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Icon from '@/components/Icon';
import { formattedDutchNumber } from '@/helpers/format';
import {
    StyledHeader,
    StyledCallUs,
    StyledHeaderBg,
    StyledMenuContactImageWrapper,
    StyledNavIcon,
    StyledPopover,
    StyledPopoverWrapper,
    StyledPopOverContent,
    StyledLogoSpan,
} from '@/pages/_app/components/Header/styles';
import { variables } from '@/assets/styled';

const Header = ({ headerMenu, contact }) => {
    const router = useRouter();
    const { asPath } = router;
    const [menuOpen, setMenuOpen] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(-10);

    useEffect(() => {
        setMenuOpen(false);
    }, [router]);

    const toggleMenu = () => {
        setMenuOpen((currentState) => !currentState);
    };

    const handleScroll = () => {
        const offset = window.pageYOffset;
        if (offset > 0) {
            setHasScrolled(true);
        } else {
            setHasScrolled(false);
        }

        if (window.innerWidth < variables.screenWidths.md && lastScrollY > 0 && window.scrollY > lastScrollY) {
            setShow(false);
        } else {
            setShow(true);
        }

        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>
            <StyledHeader className={`flex justify-center fixed w-full z-30 ${!show ? '-top-[100px] ' : 'top-0'} `}>
                <StyledHeaderBg hasScrolled={hasScrolled && show} />
                <div className="py-4 lg:py-6 container flex justify-between items-center">
                    <Link href="/">
                        <StyledLogoSpan className="relative">
                            <Image
                                src="/images/logo.svg"
                                className="cursor-pointer"
                                alt="Het logo van Blue Flamingos."
                                layout="fill"
                                objectFit="contain"
                            />
                        </StyledLogoSpan>
                    </Link>
                    <div className="flex">
                        {contact?.contact_details?.phone && (
                            <StyledCallUs className="px-4 sm:px-4 flex lg:px-12 lg:pr-14 ml-1 min-w-[63px]">
                                <p className="font-bold mr-2 hidden md:flex">Bel ons op</p>{' '}
                                <a
                                    className="font-bold relative"
                                    href={`tel:${formattedDutchNumber(contact?.contact_details?.phone)}`}
                                >
                                    <p className="text-pink cursor-pointer hidden md:flex">
                                        {contact?.contact_details?.phone}
                                    </p>
                                    <p className="md:hidden text-pink cursor-pointer whitespace-no-wrap w-16">
                                        Bel ons
                                    </p>
                                    <div className="app-underline absolute bottom-0 w-full bg-pink" />
                                </a>
                            </StyledCallUs>
                        )}
                        <a
                            className="flex items-center cursor-pointer outline-none"
                            onClick={toggleMenu}
                            role="button"
                            tabIndex="-1"
                            onKeyDown={() => {}}
                            aria-label="Toggle menu"
                        >
                            <p className="font-bold mr-2 hidden md:flex">Menu</p>
                            <StyledNavIcon className={menuOpen ? 'active' : ''}>
                                <div />
                            </StyledNavIcon>
                        </a>
                    </div>
                </div>
                <StyledPopoverWrapper
                    onClick={(event) => {
                        if (event.target === event.currentTarget) {
                            setMenuOpen(false);
                        }
                    }}
                    className={`${
                        menuOpen ? 'menu-open' : ''
                    } fixed transition-colors duration-500 ease-in-out top-0 w-full h-full z-30`}
                >
                    <StyledPopover
                        className={`opacity-0 ${
                            menuOpen ? 'menu-open' : ''
                        } transition-all duration-700 ease-in-out absolute right-0 w-full sm:w-1/2 sm:right-0 bg-text`}
                    >
                        <StyledPopOverContent className="transition-all duration-700 ease-in-out justify-center flex flex-col overflow-y-auto h-full absolute right-0 top-0 py-12 pt-20 w-full">
                            <ul className="flex flex-col justify-center align-center sm:align-start sm:ml-16">
                                {headerMenu?.map((item) => (
                                    <li key={item.title} className="flex justify-center sm:justify-start">
                                        <Link href={item.url}>
                                            <a
                                                className={`ring-1 ring-text-variant ring-opacity-5 text-display font-bold text-2xl sm:text-desktop-menu text-grey-white py-4 px-2 text-center sm:text-left leading-none sm:pl-0 ${
                                                    asPath === item.url ? 'link-active' : 'transition-all duration-200'
                                                }`}
                                            >
                                                {item.title}
                                            </a>
                                        </Link>
                                    </li>
                                ))}
                            </ul>

                            {contact && (
                                <div className="hidden sm:visible sm:pt-10 sm:flex sm:pl-16 w-full">
                                    {contact?.address_details?.image?.data?.attributes?.url && (
                                        <StyledMenuContactImageWrapper className="w-64 flex">
                                            <Image
                                                className="contain"
                                                src={`${process.env.NEXT_PUBLIC_STRAPI_URL}/${
                                                    contact?.address_details?.image?.data?.attributes?.url?.startsWith(
                                                        '/'
                                                    )
                                                        ? contact?.address_details?.image?.data?.attributes?.url?.substring(
                                                              1
                                                          )
                                                        : contact?.address_details?.image?.data?.attributes?.url
                                                }`}
                                                alt="Impressie kantoor Blue Flamingos"
                                                width="256"
                                                height="182"
                                            />
                                        </StyledMenuContactImageWrapper>
                                    )}
                                    <div className="pl-8 mt-6 flex flex-col">
                                        <p className="text-grey-ghostWhite">{contact?.address_details?.street}</p>
                                        <p className="text-grey-ghostWhite">
                                            {contact?.address_details?.postal_code} {contact?.address_details?.city}
                                        </p>
                                        <p className="text-grey-ghostWhite">{contact?.address_details?.country}</p>
                                        {contact?.contact_details?.email && (
                                            <a
                                                className="mt-4 flex items-center"
                                                href={`mailto:${contact?.contact_details?.email}`}
                                            >
                                                <Icon name="mail" fill="grey-ghostWhite" size={20} />{' '}
                                                <p className="ml-2 text-grey-ghostWhite hover:text-pink-soft">
                                                    {contact?.contact_details?.email}
                                                </p>
                                            </a>
                                        )}
                                        {contact?.contact_details?.phone && (
                                            <a
                                                className="mt-2 flex items-center"
                                                href={`tel:${formattedDutchNumber(contact?.contact_details?.phone)}`}
                                            >
                                                <Icon name="phone" fill="grey-ghostWhite" size={20} />{' '}
                                                <p className="ml-2 text-grey-ghostWhite hover:text-pink-soft">
                                                    {formattedDutchNumber(contact?.contact_details?.phone)}
                                                </p>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            )}
                        </StyledPopOverContent>
                    </StyledPopover>
                </StyledPopoverWrapper>
            </StyledHeader>
        </>
    );
};

Header.propTypes = {
    headerMenu: PropTypes.arrayOf(
        PropTypes.shape({
            menu_items: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    url: PropTypes.string,
                })
            ),
        })
    ),
    contact: PropTypes.shape({
        address_details: PropTypes.shape({
            city: PropTypes.string,
            country: PropTypes.string,
            image: PropTypes.any,
            postal_code: PropTypes.string,
            street: PropTypes.string,
        }),
        contact_details: PropTypes.shape({
            email: PropTypes.string,
            phone: PropTypes.string,
        }),
    }),
};

export default Header;
