async function fetchAPI(path) {
    const backendUrl = `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/${path}`;
    try {
        const res = await fetch(backendUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const json = await res.json();

        if (json.errors) {
            console.error(json.errors);
            throw new Error('Failed to fetch API');
        }

        return json;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export default fetchAPI;
