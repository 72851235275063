import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import PropTypes from 'prop-types';
import { shimmer, toBase64 } from '../General/Shimmer';

const PartnerCertificateBlock = ({ list }) => (
    <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-7 py-8 w-full gap-4">
        {list.map((item, index) => (
            <Link key={index} href={item.url} passHref>
                <a target={item.new_page ? '_blank' : '_self'} rel={item.follow ? 'external' : 'nofollow'}>
                    <div className="border border-grey-light group hover:shadow-md transition-all ease-in-out duration-150 cursor-pointer flex items-center justify-center p-2 rounded-md">
                        <div className="image relative h-20 w-24">
                            <Image
                                src={
                                    item.image
                                        ? `${process.env.NEXT_PUBLIC_STRAPI_URL}${item?.image?.data.attributes.url}`
                                        : '/images/no-image.png'
                                }
                                alt="Partner and Certificate logo"
                                className="group-hover:scale-90 transition-all ease-in-out duration-150 delay-100"
                                layout="fill"
                                objectFit="contain"
                                placeholder="blur"
                                blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
                            />
                        </div>
                    </div>
                </a>
            </Link>
        ))}
    </div>
);

PartnerCertificateBlock.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            follow: PropTypes.bool,
            url: PropTypes.string,
            image: PropTypes.shape({
                data: PropTypes.shape({
                    attributes: PropTypes.shape({
                        url: PropTypes.string,
                    }),
                }),
            }),
            new_page: PropTypes.boolean,
        })
    ),
};

export default PartnerCertificateBlock;
