import React from 'react';
import Link from 'next/link';

const CopyrightAndSocial = () => (
    <div className="app-m-w container md:px-8 pb-4 flex flex-wrap justify-between items-center">
        <div className="pt-4 flex flex-grow-max justify-center xs:justify-start">
            <span>
                <span className="whitespace-no-wrap text-[#66778A] text-base  mr-1">Blue Flamingos © 2024 &#124;</span>
                <Link href="/sitemap">
                    <span className="cursor-pointer whitespace-no-wrap text-[#66778A] text-base  hover:text-blue hover:text-opacity-100">
                        Sitemap
                    </span>
                </Link>
            </span>
        </div>
    </div>
);

export default CopyrightAndSocial;
