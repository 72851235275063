import PartnerCertificateBlock from '@/components/PartnerCertificateBlock';

const renderFooterComponents = (components) =>
    components.map((component, index) => {
        switch (component?.__component) {
            case 'frontend.pc-blok':
                return <PartnerCertificateBlock key={index} list={component.pc_list} />;
            default:
                return null;
        }
    });

export default renderFooterComponents;
