import '@/assets/scss/main.scss';

import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { DefaultSeo } from 'next-seo';
import TagManager from 'react-gtm-module';

import { getContactDetails, getFootermenu, getHeaderMenu } from '@/lib/api';
import Header from '@/pages/_app/components/Header';
import Footer from '@/pages/_app/components/Footer';

import { GTM_ID } from '../utils/gtm';
import { MagnetBlockProvider } from '../src/context/MagnetBlockContext';

const tagManagerArgs = {
    gtmId: GTM_ID,
};

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const [originalTitle, setOriginalTitle] = useState();
    const [headerMenu, setHeaderMenu] = useState();
    const [footerMenu, setFooterMenu] = useState();
    const [contactDetails, setContactDetails] = useState();

    const handleTabChange = () => {
        if (document.hidden) {
            document.title = '👋  Hey, we missen je!';
        } else {
            document.title = originalTitle;
        }
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const headerRes = await getHeaderMenu();
                const footerRes = await getFootermenu();
                const contactRes = await getContactDetails();

                if (headerRes.length > 0 && footerRes) {
                    setHeaderMenu(headerRes);
                    setFooterMenu(footerRes);
                    setContactDetails(contactRes);
                }
                if (contactRes) {
                    setContactDetails(contactRes);
                }
            } catch (err) {
                console.error(err);
            }
        };

        getData();
        TagManager.initialize(tagManagerArgs);
    }, []);

    useEffect(() => {
        if (!originalTitle) {
            setOriginalTitle(document.title);
        }

        window.addEventListener('visibilitychange', handleTabChange);
        return () => window.removeEventListener('visibilitychange', handleTabChange);
    }, [setOriginalTitle, handleTabChange]);

    const defaultCanonical =
        process?.env?.NEXT_PUBLIC_SITE_URL && router?.pathname
            ? `${process.env.NEXT_PUBLIC_SITE_URL}${router.pathname}`
            : '';

    useEffect(() => {
        const handleRouteChange = (url) => {
            setOriginalTitle(document.title);
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <Head>
                <link rel="icon" href="/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="google-site-verification" content="0LnYnKnsanMeuJowcmnwK9a-Dn2AnCVisRcloBLxlGQ" />
                {process.env.NODE_ENV === 'production' && (
                    <>
                        <link rel="preconnect" href="https://www.google-analytics.com" />
                        <link rel="preconnect" href="https://api.blueflamingos.nl" />
                        <link rel="dns-prefetch" href="https://www.google-analytics.com" />
                        <link
                            rel="preload"
                            as="font"
                            href="/fonts/Mont-Bold.woff2"
                            type="font/woff2"
                            crossOrigin="anonymous"
                        />
                        <link
                            rel="preload"
                            as="font"
                            href="/fonts/Mont-Heavy.woff2"
                            type="font/woff2"
                            crossOrigin="anonymous"
                        />
                    </>
                )}
            </Head>
            <Header headerMenu={headerMenu} contact={contactDetails} />
            <DefaultSeo canonical={defaultCanonical} />
            <MagnetBlockProvider initialValue={false}>
                <Component {...pageProps} />
            </MagnetBlockProvider>
            <Footer footerMenu={footerMenu} contact={contactDetails} />
        </>
    );
}

MyApp.propTypes = {
    Component: PropTypes.any,
    pageProps: PropTypes.any,
    headerMenu: PropTypes.arrayOf(
        PropTypes.shape({
            menu_items: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    url: PropTypes.string,
                })
            ),
        })
    ),
    footerMenu: PropTypes.any,
};

export default MyApp;
