import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { variables } from '@/assets/styled';

const colorsRaw = variables.colors;

const colors = {
    // Blue
    blue: colorsRaw.blue.default,
    'blue-dark': colorsRaw.blue.dark,
    'blue-light': colorsRaw.blue.light,

    // Pink
    pink: colorsRaw.pink.default,
    'pink-soft': colorsRaw.pink.soft,

    // Orange
    orange: colorsRaw.orange.default,

    // Text
    text: colorsRaw.text.default,
    'text-variant': colorsRaw.text.variant,

    // Grey
    'grey-blueLight': colorsRaw.grey.blueLight,
    'grey-blueDark': colorsRaw.grey.blueDark,
    'grey-ghostWhite': colorsRaw.grey.ghostWhite,
};

// Icons, only works for Ikonate icons by default
const Icon = ({ name, size = 16, fill = '', rotation = 0, onClick = () => {}, className = '' }) => {
    const ImportedIconRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        const importIcon = async () => {
            try {
                const { default: namedImport } = await import(`./icons/${name}.jsx`);
                ImportedIconRef.current = namedImport;
            } finally {
                setLoading(false);
            }
        };

        importIcon();
    }, [name, size, fill, rotation]);

    if (!loading && ImportedIconRef.current) {
        const { current: ImportedIcon } = ImportedIconRef;
        return (
            <>
                <ImportedIcon
                    width={size}
                    height={size}
                    color={fill ? colors[fill] : ''}
                    fill={fill ? colors[fill] : ''}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={onClick}
                    className={className}
                />
            </>
        );
    }

    return null;
};

Icon.propTypes = {
    name: PropTypes.string,
    size: PropTypes.number,
    fill: PropTypes.string,
    rotation: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Icon;
