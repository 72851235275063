import { useEffect, useState } from 'react';

export default function useMagnetBlockShown(shown) {
    const [magnetBlockShown, setMagnetBlockShown] = useState(shown);

    useEffect(() => {
        if (shown) {
            setMagnetBlockShown(true);
        }
    }, [shown, setMagnetBlockShown]);

    return [magnetBlockShown, setMagnetBlockShown];
}
