import React from 'react';
import PropTypes from 'prop-types';

const ListRenderer = ({ section }) => (
    <div key={section?.title} className="pb-1 md:px-4 w-full">
        <h2 className="text-xl text-text font-black font-display">{section?.title}</h2>
        <div className="mt-2 mb-4 flex flex-col">
            {section?.menu_items.map((menu_item, index) => (
                <a key={index} className={`flex items-center ${index > 0 ? 'mt-2' : ''}`} href={menu_item.url}>
                    <p className="hover:text-blue">{menu_item.title}</p>
                </a>
            ))}
        </div>
    </div>
);

ListRenderer.propTypes = {
    section: PropTypes.object,
};

export default ListRenderer;
